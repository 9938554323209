import {
  NorthEast as AltitudeUpIcon,
  Straighten as DistanceIcon,
  East as RightIcon,
  AccessAlarm as TimeIcon,
} from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  colors,
} from '@mui/material';
import chroma from 'chroma-js';
import type { LegWithStats, Track } from 'core';
import type { FC } from 'react';
import { PersonEntity } from '../../../components/entity/person.js';
import {
  formatAltitude,
  formatMeters,
  formatSeconds,
} from '../../../utils/format.js';
import type { LegDef } from '../types.js';
import { legDefToString } from '../utils.js';

export interface Row {
  colors: [string, string?];
  track: Track.Type;
  columns: ((LegWithStats & { highlighted: boolean }) | undefined)[];
}

export interface Column {
  highlighted: boolean;
  leg: LegDef;
}

interface Props {
  highlightLeg: (leg?: LegDef) => void;
  columns: Column[];
  rows: Row[];
}

export const CompareLegsView: FC<Props> = ({ highlightLeg, columns, rows }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell sx={{ py: 1 }}>
          <Typography variant="body2" color="text.secondary" fontStyle="italic">
            Click leg to highlight
            <RightIcon
              sx={{
                height: '1rem',
                ml: 0.5,
                width: '1rem',
                color: colors.grey[500],
                verticalAlign: 'middle',
              }}
            />
          </Typography>
        </TableCell>
        {columns.map(({ highlighted, leg }, index) => (
          <TableCell
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            key={index}
            onClick={() => highlightLeg(leg)}
            sx={{
              background: (theme) =>
                highlighted
                  ? chroma(theme.palette.primary.main).alpha(0.2).css()
                  : undefined,
              cursor: 'pointer',
              fontWeight: highlighted ? 'bold' : undefined,
              px: 2,
              py: 1,
              whiteSpace: 'nowrap',
            }}
          >
            <Typography variant="body2">{legDefToString(leg)}</Typography>
            <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
              ({leg.from._id}
              {' - '}
              {leg.to._id})
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>

    <TableBody>
      {rows.map((row, index) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        <TableRow key={index}>
          <TableCell
            sx={{
              // HACK only using user's primary color
              borderLeftColor: row.colors[0],
              borderLeftWidth: 4,
              borderLeftStyle: 'solid',
              whiteSpace: 'nowrap',
            }}
          >
            {row.track.teamName
              ? row.track.teamName
              : row.track.personId && (
                  <PersonEntity personId={row.track.personId} />
                )}
          </TableCell>
          {row.columns.map((leg, legIndex) =>
            leg?.seconds ? (
              <TableCell
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                key={legIndex}
                sx={{
                  background: (theme) =>
                    leg.highlighted
                      ? chroma(theme.palette.primary.main).alpha(0.2).css()
                      : undefined,
                  px: 2,
                  py: 1,
                  whiteSpace: 'nowrap',
                }}
              >
                <TimeIcon
                  sx={{
                    height: '0.8rem',
                    mr: 0.5,
                    width: '0.8rem',
                    color: colors.grey[500],
                    verticalAlign: 'middle',
                  }}
                />
                {formatSeconds(leg.seconds)}
                <br />
                <DistanceIcon
                  sx={{
                    height: '0.8rem',
                    mr: 0.5,
                    width: '0.8rem',
                    color: colors.grey[500],
                    verticalAlign: 'middle',
                  }}
                />
                {formatMeters(leg.stats.meters) ?? '-'}
                <br />
                <AltitudeUpIcon
                  sx={{
                    height: '0.8rem',
                    mr: 0.5,
                    width: '0.8rem',
                    color: colors.grey[500],
                    verticalAlign: 'middle',
                  }}
                />
                {formatAltitude(leg.stats.altitude.increase) ?? '-'}
              </TableCell>
            ) : (
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              <TableCell key={legIndex} sx={{ px: 2, py: 1 }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontStyle="italic"
                >
                  Missed
                </Typography>
              </TableCell>
            ),
          )}
        </TableRow>
      ))}
    </TableBody>

    <TableFooter>
      <TableRow>
        <TableCell />
        {columns.map(({ highlighted, leg }) => (
          <TableCell
            key={leg.index}
            sx={{
              background: (theme) =>
                highlighted
                  ? chroma(theme.palette.primary.main).alpha(0.2).css()
                  : undefined,
              px: 2,
              py: 1,
              whiteSpace: 'nowrap',
            }}
          >
            <Typography variant="body2">{legDefToString(leg)}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableFooter>
  </Table>
);
