import axios from 'axios';
import type { Course, Event, PaginatedList, Track } from 'core';
import { resolveLinearControls } from 'core';
import { useParams } from 'react-router-dom';
import usePromise from 'react-use-promise';
import uri from 'uri-tag';
import { MiddleSpinner } from '../../components/spinner/spinner.js';
import { ErrorPage } from '../error/page.js';
import { NotFoundPage } from '../not-found/page.js';

interface WithEventCourseProps {
  course: Course.Type;
  event: Event.WithCourses;
  tracks: Track.Type[];
}

export const withEventCourseTracks =
  (Component: React.ComponentType<WithEventCourseProps>): React.FC =>
  () => {
    const { eventId, courseId } = useParams();

    const [data, error, state] = usePromise(async () => {
      if (!eventId || !courseId) return undefined;
      const eventPromise = axios
        .get<Event.WithCourses>(uri`/api/v1/event/${eventId}`)
        .then((result) => result.data);
      const tracksPromise = axios
        .get<PaginatedList<Track.Type>>(
          uri`/api/v1/event/${eventId}/course/${courseId}/tracks`,
          {
            params: { limit: 150 },
          },
        )
        .then((result) => result.data);
      return Promise.all([eventPromise, tracksPromise]).then(
        ([event, tracks]) => {
          if (!event) return undefined;
          const course = event.courses.find((c) => c._id === courseId);
          if (!course) return undefined;

          course.controls = resolveLinearControls(
            course.controls,
            event.controls,
          );

          return {
            event,
            course,
            tracks: tracks.items.sort(
              (a, b) => a.start.when.getTime() - b.start.when.getTime(),
            ),
          };
        },
      );
    }, [eventId, courseId]);

    if (state === 'rejected') return <ErrorPage error={error} />;
    if (state === 'pending') return <MiddleSpinner />;
    if (!data) return <NotFoundPage />;

    return (
      <Component course={data.course} event={data.event} tracks={data.tracks} />
    );
  };
