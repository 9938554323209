import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  Container,
  Grid,
  List,
  Stack,
  Typography,
} from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import type { Bounds, Course, Event, ResolvedControl } from 'core';
import { DateTime } from 'luxon';
import 'mapbox-gl/dist/mapbox-gl.css';
import type { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { Map as ReactMap } from '../../../components/map/component.js';
import { CourseLayer } from '../../../components/map/layers/course.js';
import { OverlayImageLayer } from '../../../components/map/layers/overlay-image.js';
import { PageTitle } from '../../../components/page-title/page-title.js';
import { RelativeTimestamp } from '../../../components/relative-timestamp/relative-timestamp.js';
import { contrastColor } from '../colors.js';
import { SmallTrackListItemView } from '../track-list-item.js';
import type { CourseTrack, CourseTrackData } from '../types.js';
import { Chart } from './chart.js';
import { Visits } from './visits.js';

interface Props {
  bounds: Bounds;
  colors: string[];
  controls: ResolvedControl[];
  course: Course.Type;
  event: Event.Type;
  hiddenTrackIds: string[];
  mapStyle: string;
  narrowScreen: boolean;
  redact: boolean;
  toggleTrackId: (trackId: string, invert: boolean) => void;
  tracks: Record<string, CourseTrack<CourseTrackData>>;
  trackOrder: string[];
}

export const View: FC<Props> = ({
  bounds,
  colors,
  course,
  controls,
  event,
  hiddenTrackIds,
  mapStyle,
  narrowScreen,
  redact,
  toggleTrackId,
  tracks,
  trackOrder,
}) => (
  <PageTitle title={`${event.title} - ${course.title}`}>
    <Container maxWidth="xl">
      <Stack spacing={3} my={3}>
        {redact ? (
          <Alert severity="info">
            <AlertTitle>Competition in Progress</AlertTitle>
            <Typography variant="body2">
              Course details, including controls
              {(event as Event.Type & { hasOverlayImage: boolean })
                .hasOverlayImage
                ? ' and map overlay'
                : null}
              , will be visible when the competition has finished. Please check
              back{' '}
              <RelativeTimestamp
                date={DateTime.fromJSDate(event.close ?? event.finish)}
                past="now"
              />
              .
            </Typography>
          </Alert>
        ) : null}
        <Card
          sx={{
            display: 'flex',
            flex: 1,
            minHeight: { xs: undefined, md: '75vh' },
            height: { xs: undefined, md: '100%' },
            maxHeight: { xs: undefined, md: '75vh' },
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              md={3}
              sx={{ height: { xs: '50vh', md: '100%' }, overflowY: 'auto' }}
            >
              <Stack direction={narrowScreen ? 'column-reverse' : 'column'}>
                <CardContent>
                  {trackOrder.length ? (
                    <List sx={{ width: '100%' }}>
                      {trackOrder
                        .filter((trackId) => tracks[trackId])
                        .map((trackId, index) => (
                          <SmallTrackListItemView
                            key={trackId}
                            colors={
                              tracks[trackId]?.colors ?? [
                                colors[index % colors.length],
                                undefined,
                              ]
                            }
                            data={
                              !hiddenTrackIds.includes(trackId)
                                ? tracks[trackId]?.data
                                : undefined
                            }
                            highlightLeg={undefined}
                            onClick={(invert) => toggleTrackId(trackId, invert)}
                            track={tracks[trackId].track}
                            sx={{ mb: 2 }}
                          />
                        ))}
                    </List>
                  ) : (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontStyle="italic"
                    >
                      No tracks to show for this course.
                    </Typography>
                  )}
                </CardContent>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              sx={{ height: { xs: '65vh', md: '100%' } }}
            >
              <ReactMap bounds={bounds} mapStyle={mapStyle} height="100%">
                {event.overlayImage ? (
                  <OverlayImageLayer
                    corners={event.overlayImage.corners}
                    imageUrl={`/api/v1/event/${event._id}/${event.overlayImage.path}`}
                    interpolateOpacity={true}
                  />
                ) : null}
                <CourseLayer
                  controls={controls}
                  course={course}
                  event={event}
                  highlightControls={undefined}
                />
                {trackOrder
                  .map((trackId) => tracks[trackId])
                  .filter((track) => track)
                  .map((track) =>
                    track.data[0]?.geometry ? (
                      <Source
                        key={track.track._id}
                        id={`track-data-${track.track._id}`}
                        type="geojson"
                        data={track.data[0]?.geometry}
                      />
                    ) : undefined,
                  )}
                {trackOrder
                  .filter((trackId) => !hiddenTrackIds.includes(trackId))
                  .map((trackId) => tracks[trackId])
                  .filter((track) => track)
                  .map((track) =>
                    track.data[0]?.geometry ? (
                      <Layer
                        key={track.track._id}
                        id={`track-layer-casing-${track.track._id}`}
                        source={`track-data-${track.track._id}`}
                        type="line"
                        paint={{
                          'line-color':
                            track.colors.length > 1
                              ? track.colors[1]
                              : contrastColor(track.colors[0] ?? '#404040'),
                          'line-opacity': 0.65,
                          'line-width': 4.5,
                        }}
                      />
                    ) : undefined,
                  )}
                {trackOrder
                  .filter((trackId) => !hiddenTrackIds.includes(trackId))
                  .map((trackId) => tracks[trackId])
                  .filter((track) => track)
                  .map((track) =>
                    track.data[0]?.geometry ? (
                      <Layer
                        key={track.track._id}
                        id={`track-layer-${track.track._id}`}
                        source={`track-data-${track.track._id}`}
                        type="line"
                        paint={{
                          'line-color': track.colors[0],
                          'line-opacity': 1,
                          'line-width': 2.5,
                        }}
                      />
                    ) : undefined,
                  )}
              </ReactMap>
            </Grid>
          </Grid>
        </Card>
        {!redact ? (
          <>
            <Card sx={{ display: 'flex', flex: 1, overflowX: 'auto' }}>
              {controls?.length ? (
                <Visits
                  controls={controls}
                  eventType={event.type ?? 'long'}
                  items={trackOrder
                    .filter((trackId) => !hiddenTrackIds.includes(trackId))
                    .map((trackId) => tracks[trackId])
                    .filter((row) => row?.data[0])
                    .map((row) => ({
                      colors: row.colors,
                      // biome-ignore lint/style/noNonNullAssertion: <explanation>
                      legs: row.data[0]!.legs,
                      track: row.track,
                      // biome-ignore lint/style/noNonNullAssertion: <explanation>
                      visits: row.data[0]!.visits,
                    }))}
                />
              ) : (
                <CardContent>
                  <Typography color="text.secondary" fontStyle="italic">
                    No controls defined in this event.
                  </Typography>
                </CardContent>
              )}
            </Card>

            {trackOrder.filter((trackId) => !hiddenTrackIds.includes(trackId))
              .length ? (
              <ErrorBoundary
                fallback={
                  <Alert severity="error">Unable to display chart</Alert>
                }
              >
                <Card sx={{ display: 'fled', justifyContent: 'center' }}>
                  <Chart
                    items={trackOrder
                      .filter((trackId) => !hiddenTrackIds.includes(trackId))
                      .map((trackId) => tracks[trackId])
                      .filter((row) => row?.data[0])
                      .map((row) => ({
                        colors: row.colors,
                        // biome-ignore lint/style/noNonNullAssertion: <explanation>
                        legs: row.data[0]!.legs,
                        track: row.track,
                        // biome-ignore lint/style/noNonNullAssertion: <explanation>
                        visits: row.data[0]!.visits,
                      }))}
                  />
                </Card>
              </ErrorBoundary>
            ) : null}

            <Alert severity="info">
              Results are generated from GPS proximity to controls and do not
              represent official results of the event.
            </Alert>
          </>
        ) : null}
      </Stack>
    </Container>
  </PageTitle>
);
