import { Box, useTheme } from '@mui/material';
import * as d3 from 'd3';
import { select } from 'd3-selection';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';

interface Item {
  source: string;
  target: string;
  value: number;
}

const IS_TOUCH = 'ontouchstart' in window;

const ChordChard: FC<{ data: Item[]; size?: number | string }> = ({
  data,
  size = '100%',
}) => {
  // Element References
  const svgRef = useRef(null);
  const theme = useTheme();
  const colorMode = theme.palette.mode;

  useEffect(() => {
    const width = 640;
    const height = width;
    const outerRadius = Math.min(width, height) * 0.5 - 30;
    const innerRadius = outerRadius - (IS_TOUCH ? 25 : 15);

    // Compute a dense matrix from the weighted links in data.
    const names = d3.sort(
      d3.union(
        data.map((d) => d.source),
        data.map((d) => d.target),
      ),
    );
    const index = new Map<string, number>(names.map((name, i) => [name, i]));
    const matrix = Array.from(index, () =>
      new Array<number>(names.length).fill(0),
    );
    for (const { source, target, value } of data)
      matrix[index.get(source) ?? 0][index.get(target) ?? 0] += value;

    const chord = d3
      .chordDirected()
      .padAngle(10 / innerRadius)
      .sortSubgroups(d3.descending)
      .sortChords(d3.descending);

    const colors = d3.quantize(d3.interpolateRainbow, names.length + 1);

    const svg = select(svgRef.current)
      .classed('line-chart-svg', true)
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [-width / 2, -height / 2, width, height])
      .attr('style', 'width: 100%; height: 100%; font: 14px Montserrat;');

    const chords = chord(matrix);

    const group = svg
      .append('g')
      .selectAll()
      .data<d3.ChordGroup & { angle?: number }>(chords.groups)
      .join('g');

    const onTouch = (evt: MouseEvent, selected: d3.ChordGroup): void => {
      const touched = svg.attr('touched');
      onMouseOut();
      if (touched === String(selected.index)) {
        svg.attr('touched', null);
        return;
      }
      onMouseOver(evt, selected);
      svg.attr('touched', String(selected.index));
    };

    const onMouseOver = (_evt: MouseEvent, selected: d3.ChordGroup): void => {
      group.filter((d) => d.index !== selected.index).style('opacity', 0.3);

      svg
        .selectAll<
          d3.BaseType,
          { source: d3.ChordGroup; target: d3.ChordGroup }
        >('.chord')
        .filter(
          (d) =>
            d.source.index !== selected.index &&
            d.target.index !== selected.index,
        )
        .style('opacity', 0.3);
    };

    const onMouseOut = (): void => {
      group.style('opacity', 1);
      svg.selectAll('.chord').style('opacity', 1);
    };

    const arc = d3
      .arc<d3.ChordGroup>()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);

    const arcGroup = group
      .append('path')
      .attr('fill', (d) => colors[d.index])
      .attr('d', arc);

    if (IS_TOUCH) {
      arcGroup.on('touchstart', onTouch);
    } else {
      arcGroup.on('mouseover', onMouseOver).on('mouseout', onMouseOut);
    }

    group
      .append('text')
      // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
      .each((d) => (d.angle = (d.startAngle + d.endAngle) / 2))
      .attr('dy', '0.35em')
      .attr(
        'transform',
        (d) => `
        rotate(${((d.angle ?? 0) * 180) / Math.PI - 90})
        translate(${outerRadius + 8})
        ${
          (d.angle ?? 0) < Math.PI / 2 || (d.angle ?? 0) > (Math.PI * 3) / 2
            ? 'rotate(90)'
            : 'rotate(-90)'
        }
        `,
      )
      .attr('text-anchor', 'middle')
      .attr('class', 'label')
      .text((d) => names[d.index]);

    group.append('title').text((d) => names[d.index]);

    const ribbon = d3
      .ribbonArrow<unknown, d3.ChordSubgroup>()
      .radius(innerRadius - 1)
      .padAngle(1 / innerRadius);

    svg
      .append('g')
      .attr('fill-opacity', 0.75)
      .selectAll()
      .data(chords)
      .join('path')
      .attr('class', 'chord')
      .style('mix-blend-mode', colorMode === 'light' ? 'multiply' : 'screen')
      .attr('fill', (d) => colors[d.target.index])
      .attr('d', ribbon)
      .append('title')
      .text(
        (d) =>
          `${names[d.source.index]} → ${names[d.target.index]}: ${
            d.source.value
          } ${d.source.value !== 1 ? 'people' : 'person'}`,
      );

    return () => {
      svg.selectChildren().remove();
    };
  }, [data, colorMode]); // redraw chart if data changes

  return (
    <Box
      className="chord-chart"
      sx={{
        '& .label': { fill: theme.palette.text.primary },
        maxWidth: size,
        maxHeight: size,
      }}
    >
      <svg ref={svgRef} />
    </Box>
  );
};

export default ChordChard;
