import { AddTask as PointsIcon } from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  colors,
} from '@mui/material';
import type {
  Control,
  LegWithStats,
  MissedVisit,
  Track,
  VisitTime,
} from 'core';
import { defaultPointsString } from 'core';
import type { FC } from 'react';
import { PersonEntity } from '../../../components/entity/person.js';
import type { LegDef } from '../types.js';

export interface Row {
  colors: [string, string?];
  track: Track.Type;
  visits: (VisitTime | MissedVisit)[];
  legs: LegWithStats[];
}

export interface Column {
  leg: LegDef;
}

interface Props {
  eventType: string;
  rows: Row[];
}

export const VisitsView: FC<Props> = ({ eventType, rows }) => (
  <Table>
    <TableBody>
      {rows.map((row) => (
        <TableRow key={row.track._id}>
          <TableCell
            sx={{
              // HACK only using user's primary color
              borderLeftColor: row.colors[0],
              borderLeftWidth: 4,
              borderLeftStyle: 'solid',
              whiteSpace: 'nowrap',
            }}
          >
            {row.track.teamName
              ? row.track.teamName
              : row.track.personId && (
                  <PersonEntity personId={row.track.personId} />
                )}
          </TableCell>
          {row.visits.length ? (
            row.visits.map((visit, legIndex) =>
              (visit as VisitTime).nearest ? (
                <TableCell
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  key={legIndex}
                  sx={{
                    p: 1,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '1.3em',
                      fontWeight: 800,
                      color: '#A626FF',
                    }}
                  >
                    {visit.control._id}
                  </Typography>
                  {eventType === 'rogaine/points' && !visit.control.type ? (
                    <>
                      <PointsIcon
                        sx={{
                          height: '0.8rem',
                          mr: 0.5,
                          width: '0.8rem',
                          color: colors.grey[500],
                          verticalAlign: 'middle',
                        }}
                      />
                      {(visit.control as Control).points ??
                        defaultPointsString(visit.control._id, 'control')}
                    </>
                  ) : null}
                </TableCell>
              ) : null,
            )
          ) : (
            <>
              <TableCell
                colSpan={3}
                sx={{
                  p: 1,
                  textAlign: 'left',
                  whiteSpace: 'nowrap',
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontStyle="italic"
                >
                  No controls visited
                </Typography>
              </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
            </>
          )}
        </TableRow>
      ))}
    </TableBody>
  </Table>
);
