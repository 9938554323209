import {
  NorthEast as AltitudeUpIcon,
  Straighten as DistanceIcon,
  Error as ErrorIcon,
  AccessAlarm as TimeIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import type { SxProps } from '@mui/material';
import {
  Box,
  CircularProgress,
  ListItemButton,
  Stack,
  Typography,
  colors as muiColors,
  styled,
} from '@mui/material';
import type { Track } from 'core';
import type { FC } from 'react';
import { PersonEntity } from '../../components/entity/person.js';
import {
  formatAltitude,
  formatDateTime,
  formatMeters,
  formatSeconds,
} from '../../utils/format.js';
import type { UsePromiseResult } from '../../utils/promise.js';
import type { CourseTrackData, LegDef } from './types.js';

interface Props {
  colors: [string, string?];
  data?: UsePromiseResult<CourseTrackData>;
  highlightLeg: LegDef | undefined;
  onClick?: (invert: boolean) => void;
  sx?: SxProps;
  track: Track.Type;
}

const borderSecondaryColor = (color: string | undefined): SxProps | undefined =>
  color
    ? {
        borderRightColor: color,
        borderRightWidth: '4px',
        borderRightStyle: 'solid',
      }
    : undefined;

const FlexibleLayout = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
  flexDirection: 'row',
  '@container (width < 18em)': {
    flexDirection: 'column',
    '& .track-name': {
      paddingBottom: 0,
    },
  },
}));

export const TrackListItemView: FC<Props> = ({
  colors,
  data,
  highlightLeg,
  onClick,
  sx,
  track,
}) => (
  <ListItemButton
    onClick={(evt) =>
      onClick?.((evt.ctrlKey || evt.metaKey || evt.altKey) ?? false)
    }
    sx={{
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: (theme) => theme.palette.divider,
      borderRadius: (theme) => `${theme.shape.borderRadius.toString()}px`,
      p: 0,
      overflow: 'auto',
      ...sx,
    }}
    title={!data ? 'Click to show' : 'Click to hide; ALT+click to hide others'}
  >
    {!data ? (
      <VisibilityOffIcon color="disabled" sx={{ ml: 2 }} />
    ) : data[2] === 'rejected' ? (
      <ErrorIcon color="error" sx={{ ml: 2 }} />
    ) : data[2] === 'pending' ? (
      <CircularProgress size="24px" color="info" sx={{ ml: 2 }} />
    ) : (
      <Box
        sx={{
          background: colors[0],
          ...borderSecondaryColor(colors[1]),
          width: '32px',
          alignSelf: 'stretch',
        }}
      />
    )}
    <FlexibleLayout>
      <Box sx={{ flex: 1 }}>
        <Stack
          className={highlightLeg ? 'track-name' : undefined}
          sx={{ mx: 1, p: 1, minWidth: 0 }}
        >
          <Typography
            component="div"
            variant="body1"
            sx={{
              flex: 1,
              minWidth: 0,
              display: 'inline',
            }}
          >
            {track.personId ? (
              <PersonEntity avatar={!highlightLeg} personId={track.personId} />
            ) : (
              track.title
            )}
          </Typography>
          {!highlightLeg ? (
            <Typography
              variant="body2"
              fontSize="0.7rem"
              color="text.secondary"
              sx={{ flex: 1 }}
            >
              {formatDateTime(track.start.when)}
            </Typography>
          ) : null}
        </Stack>
      </Box>
      {highlightLeg && data?.[0] ? (
        <Box sx={{ pr: 2, py: 1 }}>
          {data[0].legs[highlightLeg.index + 1].seconds ? (
            <Typography
              variant="body2"
              fontSize="0.7rem"
              textAlign="right"
              sx={{ flex: 1, whiteSpace: 'nowrap' }}
            >
              <TimeIcon
                sx={{
                  height: '0.8rem',
                  mr: 0.5,
                  width: '0.8rem',
                  color: muiColors.grey[500],
                  verticalAlign: 'middle',
                }}
              />
              {formatSeconds(data[0].legs[highlightLeg.index + 1].seconds)}
              <br />
              <DistanceIcon
                sx={{
                  height: '0.8rem',
                  mr: 0.5,
                  width: '0.8rem',
                  color: muiColors.grey[500],
                  verticalAlign: 'middle',
                }}
              />
              {formatMeters(
                data[0].legs[highlightLeg.index + 1].stats.meters,
              ) ?? '-'}
              <br />
              <AltitudeUpIcon
                sx={{
                  height: '0.8rem',
                  mr: 0.5,
                  width: '0.8rem',
                  color: muiColors.grey[500],
                  verticalAlign: 'middle',
                }}
              />
              {formatAltitude(
                data[0].legs[highlightLeg.index + 1].stats.altitude.increase,
              ) ?? '-'}
            </Typography>
          ) : (
            <Typography
              variant="body2"
              fontSize="0.7rem"
              textAlign="right"
              color="text.secondary"
              fontStyle="italic"
              sx={{ flex: 1 }}
            >
              Missed
            </Typography>
          )}
        </Box>
      ) : null}
    </FlexibleLayout>
  </ListItemButton>
);

export const SmallTrackListItemView: FC<Props> = ({
  colors,
  data,
  highlightLeg,
  onClick,
  sx,
  track,
}) => (
  <ListItemButton
    onClick={(evt) =>
      onClick?.((evt.ctrlKey || evt.metaKey || evt.altKey) ?? false)
    }
    sx={{
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: (theme) => theme.palette.divider,
      borderRadius: (theme) => `${theme.shape.borderRadius.toString()}px`,
      p: 0,
      overflow: 'auto',
      ...sx,
    }}
    title={!data ? 'Click to show' : 'Click to hide; ALT+click to hide others'}
  >
    {!data ? (
      <VisibilityOffIcon color="disabled" sx={{ ml: 2 }} />
    ) : data[2] === 'rejected' ? (
      <ErrorIcon color="error" sx={{ ml: 2 }} />
    ) : data[2] === 'pending' ? (
      <CircularProgress size="24px" color="info" sx={{ ml: 2 }} />
    ) : (
      <Box
        sx={{
          background: colors[0],
          ...borderSecondaryColor(colors[1]),
          width: '32px',
          alignSelf: 'stretch',
        }}
      />
    )}
    <Stack sx={{ mx: 1, p: 1 }}>
      <Typography component="div" variant="body1" sx={{ flex: 1 }}>
        {track.teamName ? (
          track.teamName
        ) : track.personId ? (
          <PersonEntity avatar={!highlightLeg} personId={track.personId} />
        ) : (
          track.title
        )}
      </Typography>
    </Stack>
  </ListItemButton>
);
