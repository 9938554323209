import chroma from 'chroma-js';
import ColorHash from 'color-hash';

export const colors = [
  '#e6194B',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#7a0172',
  '#42d4f4',
  '#f032e6',
  '#3cb44b',
  '#bfef45',
  '#fabed4',
  '#469990',
  '#dcbeff',
  '#9A6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#a9a9a9',
  '#ffffff',
  '#000000',
];

export const colorScale = chroma.scale(['#0f0', '#ff0', '#f00']).mode('lch');

export const colorHash = new ColorHash({ saturation: 1 });

export const contrastColor = (color: string): string => {
  const fg = chroma(color);
  return fg.luminance() > 0.4 ? '#000000' : '#ffffff';
};
