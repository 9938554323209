import type {
  Control,
  Course,
  CourseControl,
  Event,
  MissedVisit,
  ResolvedControl,
  Track,
  VisitTime,
} from 'core';
import { useEffect, useMemo, useReducer } from 'react';
import { colorHash } from './colors.js';
import { loadTrackData } from './load-track-data.js';
import { reducer } from './reducer.js';
import type { CourseTrack, CourseTrackData } from './types.js';

interface Props {
  course: Course.Type;
  event: Event.WithCourses;
  tracks: Track.Type[];
}

export interface WithTracksDataProps<T> extends Props {
  controls: ResolvedControl[];
  tracksData: Record<string, CourseTrack<T>>;
}

type ControlResolver = (
  courseControls: CourseControl[] | undefined,
  eventControls: Record<string, Control> | undefined,
) => ResolvedControl[];

export type VisitMatcher = (
  visitTimes: VisitTime[],
  controls: ResolvedControl[],
  track: Track.Type,
) => (VisitTime | MissedVisit)[];

export const withTracksData =
  (resolveControls: ControlResolver, matchVisits: VisitMatcher) =>
  (
    Component: React.ComponentType<WithTracksDataProps<CourseTrackData>>,
  ): React.FC<Props> =>
  ({ event, course, tracks }) => {
    const [tracksData, dispatch] = useReducer(
      reducer<CourseTrackData>,
      tracks.reduce<Record<string, CourseTrack<CourseTrackData>>>(
        (acc, track) => ({
          ...acc,
          [track._id]: {
            colors: [
              colorHash.hex(track.personId),
              colorHash.hex(track.personId.slice(-8)),
            ],
            track,
            data: [undefined, undefined, 'pending'],
          },
        }),
        {},
      ),
    );

    const controls = useMemo(
      () => resolveControls(course.controls, event.controls),
      [event, course, resolveControls],
    );

    useEffect(() => {
      const controller = new AbortController();
      tracks.map((track) =>
        loadTrackData(
          track,
          event.type,
          course.defaultControlRadius ?? event.defaultControlRadius,
          controls,
          (trackId, data) => dispatch({ type: 'TRACK_UPDATED', data, trackId }),
          (visitTimes) => matchVisits(visitTimes, controls, track),
          controller.signal,
        ),
      );

      return () => controller.abort();
    }, [event, course, tracks, controls, matchVisits]);

    return (
      <Component
        controls={controls}
        course={course}
        event={event}
        tracks={tracks}
        tracksData={tracksData}
      />
    );
  };
