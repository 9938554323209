import { useMediaQuery, type Theme } from '@mui/material';
import type { AllGeoJSON, BBox } from '@turf/turf';
import { bbox, buffer, center, circle, helpers } from '@turf/turf';
import type { CourseControl, LegTimedStats, LocationDataPoint } from 'core';
import {
  bboxToBounds,
  combineBbox,
  controlsToGeometry,
  getEventScale,
  linearMatch,
  mustRedact,
  resolveLinearControls,
} from 'core';
import { useState } from 'react';
import { useAuthenticated } from '../../../auth/auth-provider.js';
import { MapStyles } from '../../../components/map/map-styles.js';
import { useLocalStorage } from '../../../hooks/use-local-storage.js';
import { colorScale, colors } from '../colors.js';
import type {
  Highlight,
  LegDef,
  StatsColor,
  TrackHighlight,
} from '../types.js';
import { asScale, bestWorst } from '../utils.js';
import type { VisitMatcher } from '../with-tracks-data.js';
import { withTracksData } from '../with-tracks-data.js';
import { View } from './view.jsx';

const matchVisits: VisitMatcher = (visitTimes, controls, track) =>
  linearMatch(visitTimes, controls, track.skippedFirstControls);

export const LinearController = withTracksData(
  resolveLinearControls,
  matchVisits,
)(({ controls, course, event, tracks, tracksData }) => {
  const narrowScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const { person } = useAuthenticated();
  const [hiddenTrackIds, setHiddenTrackIds] = useState<string[]>([]);
  const [highlight, setHighlight] = useState<Highlight>();
  const [nextLeg, setNextLeg] = useState<LegDef | undefined>(
    getFirstLeg(controls),
  );
  const [prevLeg, setPrevLeg] = useState<LegDef | undefined>(
    getLastLeg(controls),
  );
  const [colorBy, setColorBy] = useLocalStorage<keyof StatsColor | undefined>(
    'color-by',
    undefined,
  );

  const toggleTrackId = (trackId: string, invert: boolean): void => {
    if (invert) {
      if (
        hiddenTrackIds.length === tracks.length - 1 &&
        !hiddenTrackIds.some((id) => id === trackId)
      ) {
        setHiddenTrackIds([]);
      } else {
        setHiddenTrackIds(
          tracks.map((track) => track._id).filter((id) => id !== trackId),
        );
      }
    } else {
      if (hiddenTrackIds.includes(trackId)) {
        // show
        setHiddenTrackIds(hiddenTrackIds.filter((id) => id !== trackId));
      } else {
        // hide
        setHiddenTrackIds([...hiddenTrackIds, trackId]);
      }
    }
  };

  const highlightLeg = (legDef?: LegDef): void => {
    if (
      !legDef ||
      (highlight?.legDef.from._id === legDef.from._id &&
        highlight?.legDef.to._id === legDef.to._id)
    ) {
      setHighlight(undefined);

      setNextLeg(getFirstLeg(controls));
      setPrevLeg(getLastLeg(controls));
      return;
    }

    const legIndex = controls
      .slice(1)
      .map((control, index) => [controls[index]._id, control._id])
      .findIndex(
        ([from, to]) => from === legDef.from._id && to === legDef.to._id,
      );

    const prevLegDef: LegDef | undefined =
      !controls || controls?.length < 2
        ? undefined
        : legIndex === undefined || legIndex === 0
          ? getLastLeg(controls)
          : {
              from: controls?.[legIndex - 1],
              index: legIndex - 1,
              to: controls?.[legIndex],
            };

    const nextLegDef: LegDef | undefined =
      !controls || controls?.length < 2
        ? undefined
        : legIndex === undefined || legIndex >= controls.length - 2
          ? getFirstLeg(controls)
          : {
              from: controls?.[legIndex + 1],
              index: legIndex + 1,
              to: controls?.[legIndex + 2],
            };

    // compute best and worst stats
    const { best, worst } = Array.from(Object.values(tracksData)).reduce<{
      best: LegTimedStats;
      worst: LegTimedStats;
    }>(
      (acc, track) => {
        const matchingLeg = track.data[0]?.legs.find(
          (leg) =>
            leg.from?.control._id === legDef.from._id &&
            leg.to?.control._id === legDef.to._id,
        );
        if (!matchingLeg || !matchingLeg.locations) return acc;

        return bestWorst(acc, {
          ...matchingLeg.stats,
          seconds: matchingLeg.seconds,
        });
      },
      { best: { altitude: {} }, worst: { altitude: {} } },
    );

    const trackHighlights = Array.from(Object.values(tracksData)).reduce<
      TrackHighlight[]
    >((acc, track) => {
      const matchingLeg = track.data[0]?.legs.find(
        (leg) =>
          leg.from?.control._id === legDef.from._id &&
          leg.to?.control._id === legDef.to._id,
      );
      if (!matchingLeg || !matchingLeg.locations?.length) return acc;

      const coordinates = matchingLeg.locations.map((point) => [
        point.long,
        point.lat,
      ]);
      const lineString = helpers.lineStrings([coordinates]);
      return [
        ...acc,
        {
          trackId: track.track._id,
          colors: track.colors,
          statsColor: {
            meters: colorScale(
              asScale(best.meters, worst.meters, matchingLeg.stats.meters),
            ).css(),
            seconds: colorScale(
              asScale(best.seconds, worst.seconds, matchingLeg.seconds),
            ).css(),
            secondsPerMeter: colorScale(
              asScale(
                best.secondsPerMeter,
                worst.secondsPerMeter,
                matchingLeg.stats.secondsPerMeter,
              ),
            ).css(),
            altitudeIncrease: colorScale(
              asScale(
                best.altitude.increase,
                worst.altitude.increase,
                matchingLeg.stats.altitude.increase,
              ),
            ).css(),
          },
          geometry: lineString,
          locations: matchingLeg.locations,
          matchingLeg,
        } satisfies TrackHighlight,
      ];
    }, []);

    setHighlight({
      courseLines: [],
      legDef,
      tracks: trackHighlights,
    });

    setNextLeg(nextLegDef);
    setPrevLeg(prevLegDef);
  };

  const boundsArray = highlight
    ? [
        ...legToBbox(highlight.legDef).map(bbox),
        ...highlight.tracks.reduce<BBox[]>(
          (acc, track) =>
            track.locations.length
              ? [
                  ...acc,
                  bbox(track.geometry),
                  bbox(locationToCircle(track.locations[0])),
                  bbox(
                    locationToCircle(
                      track.locations[track.locations.length - 1],
                    ),
                  ),
                ]
              : [...acc, bbox(track.geometry)],
          [],
        ),
      ]
    : Object.values(tracksData)
        .map((s) => s.data)
        .filter((data) => data[0] && data[2] === 'resolved')
        // biome-ignore lint/style/noNonNullAssertion:
        .map((data) => data[0]!.bounds);

  if (!highlight && controls?.length) {
    boundsArray.push(
      bbox(controlsToGeometry(controls, getEventScale(event.type))),
    );
  }

  const bounds = boundsArray.length
    ? combineBbox(boundsArray)
    : bbox(buffer(center(event.location as AllGeoJSON), 0.5));

  const redact = mustRedact(event, person._id);

  return View({
    bounds: bboxToBounds(bounds),
    colorBy,
    colors,
    controls,
    course,
    event,
    hiddenTrackIds,
    highlight,
    highlightLeg,
    mapStyle: MapStyles.SATELLITE,
    narrowScreen,
    nextLeg,
    prevLeg,
    redact,
    setColorBy: (value) =>
      setColorBy(
        ['meters', 'seconds', 'secondsPerMeter', 'altitudeIncrease'].includes(
          value,
        )
          ? (value as keyof StatsColor)
          : undefined,
      ),
    toggleTrackId,
    tracks: tracksData,
    trackOrder: tracks.map((track) => track._id),
  });
});

const locationToCircle = (location: LocationDataPoint): helpers.Feature =>
  circle([location.long, location.lat], 30, { units: 'meters' });

const legToBbox = (leg: LegDef): helpers.Feature[] =>
  [courseControlToCircle(leg.from), courseControlToCircle(leg.to)].filter(
    (f) => f !== undefined,
  ) as helpers.Feature[];

const courseControlToCircle = (
  control: CourseControl,
): helpers.Feature | undefined =>
  control.long === undefined || control.lat === undefined
    ? undefined
    : circle([control.long, control.lat], (control.radius ?? 30) + 10, {
        units: 'meters',
      });

const getFirstLeg = (
  controls: CourseControl[] | undefined,
): LegDef | undefined =>
  controls && controls.length >= 2
    ? { from: controls[0], index: 0, to: controls[1] }
    : undefined;

const getLastLeg = (
  controls: CourseControl[] | undefined,
): LegDef | undefined =>
  controls && controls.length >= 2
    ? {
        from: controls[controls.length - 2],
        index: controls.length - 2,
        to: controls[controls.length - 1],
      }
    : undefined;
