import type {
  CourseControl,
  LegWithStats,
  MissedVisit,
  Track,
  VisitTime,
} from 'core';
import type { FC } from 'react';
import { VisitsView } from './visits.view.js';

export interface Row {
  colors: [string, string?];
  track: Track.Type;
  visits: (VisitTime | MissedVisit)[];
  legs: LegWithStats[];
}

interface Props {
  controls: CourseControl[];
  eventType: string;
  items: Row[];
}

export const Visits: FC<Props> = ({ controls, eventType, items }) => {
  return VisitsView({ eventType, rows: items });
};
