import { isRogaine } from 'core';
import { LinearController } from './linear/controller.js';
import { RogaineController } from './rogaine/controller.js';
import { withEventCourseTracks } from './with-event-course-tracks.js';

const Controller = withEventCourseTracks(({ event, course, tracks }) => {
  if (isRogaine(event.type)) {
    return RogaineController({ event, course, tracks });
  }

  return LinearController({ event, course, tracks });
});

export default Controller;
