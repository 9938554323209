import { captureException } from '@sentry/browser';
import { bbox } from '@turf/turf';
import axios, { isCancel, type GenericAbortSignal } from 'axios';
import type {
  DataPoint,
  MissedVisit,
  ResolvedControl,
  Track,
  VisitTime,
} from 'core';
import {
  computeVisitTimes,
  flattenLocationSegments,
  segmentsToLineString,
  toLegs,
} from 'core';
import pLimit from 'p-limit';
import uri from 'uri-tag';
import type { UsePromiseResult } from '../../utils/promise.js';
import type { CourseTrackData } from './types.js';

const limit = pLimit(10);

export const loadTrackData = (
  track: Track.Type,
  eventType: string | null | undefined,
  radiusOverride: number | null | undefined,
  controls: ResolvedControl[],
  onUpdate: (
    trackId: string,
    result: UsePromiseResult<CourseTrackData>,
  ) => void,
  matchVisits: (visitTimes: VisitTime[]) => (VisitTime | MissedVisit)[],
  signal: GenericAbortSignal,
): void => {
  onUpdate(track._id, [undefined, undefined, 'pending']);

  limit(() =>
    axios
      .get<DataPoint[][]>(uri`/api/v1/track/${track._id}/points`, { signal })
      .then(({ data }) => {
        const locations = flattenLocationSegments(data);
        const visitTimes = computeVisitTimes(
          locations,
          controls,
          eventType,
          radiusOverride,
        );
        const visits = matchVisits(visitTimes);
        const legs = toLegs(visits, locations);
        const geometry = segmentsToLineString(data);
        const bounds = bbox(geometry);

        if (signal.aborted) return;
        onUpdate(track._id, [
          { bounds, geometry, legs, visits },
          undefined,
          'resolved',
        ]);
        return;
      }),
  ).catch((err) => {
    if (isCancel(err)) return;

    console.error(err);
    captureException(err);
    onUpdate(track._id, [undefined, err, 'rejected']);
  });
};
