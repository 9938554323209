import {
  ChevronRight as NextIcon,
  ChevronLeft as PrevIcon,
} from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  MenuItem,
  Select,
  Stack,
  Typography,
  colors as muiColors,
  styled,
} from '@mui/material';
import type { Bounds, Course, Event, ResolvedControl, Track } from 'core';
import { DateTime } from 'luxon';
import 'mapbox-gl/dist/mapbox-gl.css';
import type { FC } from 'react';
import { Layer, Source } from 'react-map-gl';
import { InfoFieldView } from '../../../components/info-field/info-field.js';
import { Map as ReactMap } from '../../../components/map/component.js';
import { CourseLayer } from '../../../components/map/layers/course.js';
import { OverlayImageLayer } from '../../../components/map/layers/overlay-image.js';
import { PageTitle } from '../../../components/page-title/page-title.js';
import { RelativeTimestamp } from '../../../components/relative-timestamp/relative-timestamp.js';
import {
  formatDate,
  formatMeters,
  formatSeconds,
  formatSecondsPerMeter,
} from '../../../utils/format.js';
import { contrastColor } from '../colors.js';
import { TrackListItemView } from '../track-list-item.js';
import type {
  CourseTrack,
  CourseTrackData,
  Highlight,
  LegDef,
  StatsColor,
} from '../types.js';
import { legDefToString } from '../utils.js';
import { CompareLegs } from './compare-legs.js';

interface Props {
  bounds: Bounds;
  colorBy: keyof StatsColor | undefined;
  colors: string[];
  controls: ResolvedControl[];
  course: Course.Type;
  event: Event.Type;
  hiddenTrackIds: string[];
  highlight: Highlight | undefined;
  highlightLeg: (leg?: LegDef) => void;
  mapStyle: string;
  narrowScreen: boolean;
  nextLeg: LegDef | undefined;
  prevLeg: LegDef | undefined;
  redact: boolean;
  setColorBy: (value: string) => void;
  toggleTrackId: (trackId: string, invert: boolean) => void;
  tracks: Record<string, CourseTrack<CourseTrackData>>;
  trackOrder: string[];
}

const OnlyWide = styled('div')(({ theme }) => ({
  '@container (width < 18em)': {
    display: 'none',
  },
}));

export const View: FC<Props> = ({
  bounds,
  colorBy,
  colors,
  controls,
  course,
  hiddenTrackIds,
  event,
  highlight,
  highlightLeg,
  mapStyle,
  narrowScreen,
  nextLeg,
  prevLeg,
  redact,
  setColorBy,
  toggleTrackId,
  tracks,
  trackOrder,
}) => (
  <PageTitle title={`${event.title} - ${course.title}`}>
    <Container maxWidth="xl">
      <Stack spacing={3} my={3}>
        {redact ? (
          <Alert severity="info">
            <AlertTitle>Competition in Progress</AlertTitle>
            <Typography variant="body2">
              Course details, including controls
              {(event as Event.Type & { hasOverlayImage: boolean })
                .hasOverlayImage
                ? ' and map overlay'
                : null}
              , will be visible when the competition has finished. Please check
              back{' '}
              <RelativeTimestamp
                date={DateTime.fromJSDate(event.close ?? event.finish)}
                past="now"
              />
              .
            </Typography>
          </Alert>
        ) : null}
        <Card
          sx={{
            display: 'flex',
            flex: 1,
            minHeight: { xs: undefined, md: '75vh' },
            height: { xs: undefined, md: '100%' },
            maxHeight: { xs: undefined, md: '75vh' },
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              md={3}
              sx={{ height: { xs: '50vh', md: '100%' } }}
            >
              <Stack
                direction={narrowScreen ? 'column-reverse' : 'column'}
                height="100%"
                style={{ containerType: 'size' }}
              >
                <ButtonGroup variant="outlined" fullWidth>
                  <Button
                    disabled={!prevLeg}
                    onClick={() => highlightLeg(prevLeg)}
                    startIcon={<PrevIcon />}
                    sx={{ whiteSpace: 'nowrap' }}
                    title="Highlight and zoom to previous leg"
                  >
                    <OnlyWide>
                      {prevLeg ? legDefToString(prevLeg) : 'Prev'}
                    </OnlyWide>
                  </Button>
                  <Button
                    sx={{
                      color: (theme) => theme.palette.text.primary,
                      whiteSpace: 'nowrap',
                    }}
                    disabled={!highlight}
                    onClick={() => highlightLeg()}
                    title={
                      highlight ? 'Zoom to whole course and no hightlight' : ''
                    }
                  >
                    {highlight ? (
                      <>
                        <OnlyWide>Leg&nbsp;</OnlyWide>
                        <strong>{legDefToString(highlight.legDef)}</strong>
                      </>
                    ) : (
                      'All'
                    )}
                  </Button>
                  <Button
                    disabled={!nextLeg}
                    endIcon={<NextIcon />}
                    onClick={() => highlightLeg(nextLeg)}
                    sx={{ whiteSpace: 'nowrap' }}
                    title="Highlight and zoom to next leg"
                  >
                    <OnlyWide>
                      {nextLeg ? legDefToString(nextLeg) : 'Next'}
                    </OnlyWide>
                  </Button>
                </ButtonGroup>
                <CardContent
                  sx={{ display: 'block', flex: 1, overflowY: 'auto', p: 2 }}
                >
                  <Box>
                    {highlight ? (
                      <FormControl
                        size="small"
                        sx={{ height: '1.7em', width: '100%' }}
                      >
                        <InputLabel id="color-by-label">Color By</InputLabel>
                        <Select
                          sx={{ height: '1.7em' }}
                          labelId="color-by-label"
                          id="color-by-select"
                          value={colorBy ?? 'track'}
                          label="Color By"
                          onChange={(e) => setColorBy(e.target.value)}
                        >
                          <MenuItem value={'track'}>Track</MenuItem>
                          <MenuItem value={'seconds'}>Time</MenuItem>
                          <MenuItem value={'meters'}>Distance</MenuItem>
                          <MenuItem value={'secondsPerMeter'}>Pace</MenuItem>
                          <MenuItem value={'altitudeIncrease'}>
                            Altitude Increase
                          </MenuItem>
                        </Select>
                      </FormControl>
                    ) : null}
                    {trackOrder.length ? (
                      <List sx={{ width: '100%' }}>
                        {trackOrder
                          .filter((trackId) => tracks[trackId])
                          .map((trackId, index) => (
                            <TrackListItemView
                              key={trackId}
                              colors={
                                highlight && colorBy
                                  ? [
                                      highlight.tracks.find(
                                        (track) => track.trackId === trackId,
                                      )?.statsColor[colorBy] ??
                                        muiColors.grey[500],
                                      undefined,
                                    ]
                                  : tracks[trackId]?.colors ?? [
                                      colors[index % colors.length],
                                      undefined,
                                    ]
                              }
                              data={
                                !hiddenTrackIds.includes(trackId)
                                  ? tracks[trackId]?.data
                                  : undefined
                              }
                              highlightLeg={highlight?.legDef}
                              onClick={(invert) =>
                                toggleTrackId(trackId, invert)
                              }
                              track={tracks[trackId].track}
                              sx={{ mb: 1 }}
                            />
                          ))}
                      </List>
                    ) : (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        fontStyle="italic"
                      >
                        No tracks to show for this course.
                      </Typography>
                    )}
                  </Box>
                </CardContent>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              sx={{ height: { xs: '65vh', md: '100%' } }}
            >
              <ReactMap bounds={bounds} mapStyle={mapStyle} height="100%">
                {event.overlayImage ? (
                  <OverlayImageLayer
                    corners={event.overlayImage.corners}
                    imageUrl={`/api/v1/event/${event._id}/${event.overlayImage.path}`}
                    interpolateOpacity={true}
                  />
                ) : null}
                <CourseLayer
                  controls={controls}
                  course={course}
                  event={event}
                  highlightControls={
                    highlight?.legDef
                      ? [highlight.legDef.from, highlight.legDef.to]
                      : undefined
                  }
                />
                {trackOrder
                  .map((trackId) => tracks[trackId])
                  .filter((track) => track)
                  .map((track) =>
                    track.data[0]?.geometry ? (
                      <Source
                        key={track.track._id}
                        id={`track-data-${track.track._id}`}
                        type="geojson"
                        data={track.data[0]?.geometry}
                      />
                    ) : undefined,
                  )}
                {highlight?.tracks
                  .filter((track) => !hiddenTrackIds.includes(track.trackId))
                  .map((track) => (
                    <Source
                      key={track.trackId}
                      id={`track-data-highlight-${track.trackId}`}
                      type="geojson"
                      data={track.geometry}
                    />
                  ))}
                {trackOrder
                  .filter((trackId) => !hiddenTrackIds.includes(trackId))
                  .map((trackId) => tracks[trackId])
                  .filter((track) => track)
                  .map((track) =>
                    track.data[0]?.geometry ? (
                      <Layer
                        key={track.track._id}
                        id={`track-layer-casing-${track.track._id}`}
                        source={`track-data-${track.track._id}`}
                        type="line"
                        layout={{
                          'line-cap': 'butt',
                          'line-join': 'round',
                        }}
                        paint={{
                          'line-color':
                            track.colors.length > 1
                              ? track.colors[1]
                              : contrastColor(track.colors[0] ?? '#404040'),
                          'line-opacity': highlight ? 0 : 0.65,
                          'line-width': 4.5,
                        }}
                      />
                    ) : undefined,
                  )}
                {highlight?.tracks
                  .filter((track) => !hiddenTrackIds.includes(track.trackId))
                  .map((track) => (
                    <Layer
                      key={track.trackId}
                      id={`track-layer-highlight-casing-${track.trackId}`}
                      source={`track-data-highlight-${track.trackId}`}
                      type="line"
                      layout={{
                        'line-cap': 'butt',
                        'line-join': 'round',
                      }}
                      paint={{
                        'line-color': colorBy
                          ? contrastColor(
                              // biome-ignore lint/style/noNonNullAssertion: <explanation>
                              track.statsColor[colorBy!] ?? '#404040',
                            )
                          : track.colors.length > 1
                            ? track.colors[1]
                            : contrastColor(track.colors[0] ?? '#404040'),
                        'line-opacity': 0.65,
                        'line-width': 6.5,
                      }}
                    />
                  ))}
                {trackOrder
                  .filter((trackId) => !hiddenTrackIds.includes(trackId))
                  .map((trackId) => tracks[trackId])
                  .filter((track) => track)
                  .map((track) =>
                    track.data[0]?.geometry ? (
                      <Layer
                        key={track.track._id}
                        id={`track-layer-${track.track._id}`}
                        source={`track-data-${track.track._id}`}
                        type="line"
                        layout={{
                          'line-cap': 'butt',
                          'line-join': 'round',
                        }}
                        paint={{
                          'line-color': track.colors[0],
                          'line-opacity': highlight ? 0.15 : 1,
                          'line-width': 2.5,
                        }}
                      />
                    ) : undefined,
                  )}
                {highlight?.tracks
                  .filter((track) => !hiddenTrackIds.includes(track.trackId))
                  .map((track) => (
                    <Layer
                      key={track.trackId}
                      id={`track-layer-highlight-${track.trackId}`}
                      source={`track-data-highlight-${track.trackId}`}
                      type="line"
                      layout={{
                        'line-cap': 'butt',
                        'line-join': 'round',
                      }}
                      paint={{
                        'line-color': colorBy
                          ? track.statsColor[colorBy]
                          : track.colors[0],
                        'line-width': 3.0,
                      }}
                    />
                  ))}
              </ReactMap>
            </Grid>
          </Grid>
        </Card>
        {!redact &&
        trackOrder.filter((trackId) => !hiddenTrackIds.includes(trackId))
          .length ? (
          <>
            <Card sx={{ display: 'flex', flex: 1, overflowX: 'auto' }}>
              {course.controls?.length ? (
                <CompareLegs
                  controls={course.controls}
                  highlight={highlight}
                  highlightLeg={highlightLeg}
                  items={trackOrder
                    .filter((trackId) => !hiddenTrackIds.includes(trackId))
                    .map((trackId) => tracks[trackId])
                    .filter((row) => row?.data[0])
                    .map((row) => ({
                      colors: row.colors,
                      // biome-ignore lint/style/noNonNullAssertion: <explanation>
                      legs: row.data[0]!.legs,
                      track: row.track,
                      // biome-ignore lint/style/noNonNullAssertion: <explanation>
                      visits: row.data[0]!.visits,
                    }))}
                />
              ) : (
                <CardContent>
                  <Typography color="text.secondary" fontStyle="italic">
                    No controls defined in this course.
                  </Typography>
                </CardContent>
              )}
            </Card>
            <Alert severity="info">
              Results are generated from GPS proximity to controls and do not
              represent official results of the event.
            </Alert>
          </>
        ) : null}
      </Stack>
    </Container>
  </PageTitle>
);

export const TrackInfoView: FC<{ track: Track.Type }> = ({ track }) => (
  <Stack spacing={1} sx={{ alignItems: 'stretch', flex: 1 }}>
    <Box display="flex" flex={1} justifyContent="space-between">
      <Typography variant="h3">{track.title}</Typography>
      <Chip label={track.originalFormat} size="small" />
    </Box>
    <Stack
      direction="row"
      spacing={3}
      sx={{
        display: 'flex',
        m: 3,
        alignItems: 'start',
        width: '100%',
        overflowY: 'clip',
        overflowX: 'auto',
      }}
    >
      <InfoFieldView label="Date">{formatDate(track.start.when)}</InfoFieldView>
      <Divider orientation="vertical" sx={{ height: '2rem' }} />
      <InfoFieldView label="Duration">
        {formatSeconds(track.stats.seconds)}
      </InfoFieldView>
      {track.stats.meters ? (
        <>
          <Divider orientation="vertical" sx={{ height: '2rem' }} />
          <InfoFieldView label="Distance">
            {formatMeters(track.stats.meters) ?? '-'}
          </InfoFieldView>
          <Divider orientation="vertical" sx={{ height: '2rem' }} />
          <InfoFieldView label="Pace">
            {formatSecondsPerMeter(
              track.stats.meters
                ? track.stats.seconds / track.stats.meters
                : undefined,
            ) ?? '-'}
          </InfoFieldView>
        </>
      ) : undefined}
    </Stack>
  </Stack>
);
