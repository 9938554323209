import { useMediaQuery, type Theme } from '@mui/material';
import type { AllGeoJSON } from '@turf/turf';
import { bbox, buffer, center } from '@turf/turf';
import {
  bboxToBounds,
  combineBbox,
  controlsToGeometry,
  getEventScale,
  mustRedact,
  resolveLinearControls,
  resolveRogaineControls,
  rogaineMatch,
} from 'core';
import { useState } from 'react';
import { useAuthenticated } from '../../../auth/auth-provider.js';
import { MapStyles } from '../../../components/map/map-styles.js';
import { colors } from '../colors.js';
import type { VisitMatcher } from '../with-tracks-data.js';
import { withTracksData } from '../with-tracks-data.js';
import { View } from './view.jsx';

const matchVisits: VisitMatcher = (visitTimes, controls, _track) =>
  rogaineMatch(visitTimes, controls);

export const RogaineController = withTracksData(
  resolveRogaineControls,
  matchVisits,
)(({ controls, course, event, tracks, tracksData }) => {
  const narrowScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const { person } = useAuthenticated();
  const [hiddenTrackIds, setHiddenTrackIds] = useState<string[]>([]);

  const toggleTrackId = (trackId: string, invert: boolean): void => {
    if (invert) {
      if (
        hiddenTrackIds.length === tracks.length - 1 &&
        !hiddenTrackIds.some((id) => id === trackId)
      ) {
        setHiddenTrackIds([]);
      } else {
        setHiddenTrackIds(
          tracks.map((track) => track._id).filter((id) => id !== trackId),
        );
      }
    } else {
      if (hiddenTrackIds.includes(trackId)) {
        // show
        setHiddenTrackIds(hiddenTrackIds.filter((id) => id !== trackId));
      } else {
        // hide
        setHiddenTrackIds([...hiddenTrackIds, trackId]);
      }
    }
  };

  const boundsArray = Object.values(tracksData)
    .map((s) => s.data)
    .filter((data) => data[2] === 'resolved')
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    .map((data) => data[0]!.bounds);

  if (course?.controls?.length) {
    const controls = resolveLinearControls(course.controls, event.controls);
    boundsArray.push(
      bbox(controlsToGeometry(controls, getEventScale(event.type))),
    );
  }

  const bounds = boundsArray.length
    ? combineBbox(boundsArray)
    : bbox(buffer(center(event.location as AllGeoJSON), 0.5));

  const redact = mustRedact(event, person._id);

  return View({
    bounds: bboxToBounds(bounds),
    colors,
    controls,
    course,
    event,
    hiddenTrackIds,
    mapStyle: MapStyles.SATELLITE,
    narrowScreen,
    redact,
    toggleTrackId,
    tracks: tracksData,
    trackOrder: tracks.map((track) => track._id),
  });
});
