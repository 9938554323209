import type {
  CourseControl,
  LegWithStats,
  MissedVisit,
  Track,
  VisitTime,
} from 'core';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { Highlight, LegDef } from '../types.js';
import type { Column } from './compare-legs.view.js';
import { CompareLegsView } from './compare-legs.view.js';

export interface Row {
  colors: [string, string?];
  track: Track.Type;
  visits: (VisitTime | MissedVisit)[];
  legs: LegWithStats[];
}

interface Props {
  controls: CourseControl[];
  highlight: Highlight | undefined;
  highlightLeg: (leg?: LegDef) => void;
  items: Row[];
}

interface Reduction {
  prev: CourseControl;
  columns: Column[];
}

export const CompareLegs: FC<Props> = ({
  controls,
  highlight,
  highlightLeg,
  items,
}) => {
  const { columns } = useMemo(
    () =>
      controls.slice(1).reduce<Reduction>(
        (acc, control, index) => ({
          prev: control,
          columns: [
            ...acc.columns,
            {
              highlighted: isHighlighted(highlight, {
                from: acc.prev,
                index,
                to: control,
              }),
              leg: { from: acc.prev, index, to: control },
            },
          ],
        }),
        {
          prev: controls[0],
          columns: [],
        },
      ),
    [controls, highlight, controls[0]],
  );

  const rows = useMemo(
    () =>
      items.map((row) => {
        return {
          colors: row.colors,
          track: row.track,
          columns: columns.map((column) => {
            const leg = row.legs.find(
              (leg) =>
                leg.from?.control._id === column.leg.from._id &&
                leg.to?.control._id === column.leg.to._id,
            );
            return leg
              ? { ...leg, highlighted: column.highlighted }
              : undefined;
          }),
        };
      }),
    [items, columns],
  );

  return CompareLegsView({ highlightLeg, columns, rows });
};

const isHighlighted = (
  highlight: Highlight | undefined,
  leg: LegDef,
): boolean =>
  (highlight &&
    highlight.legDef.from._id === leg.from._id &&
    highlight.legDef.to._id === leg.to._id) ??
  false;
