import type { LegWithStats, MissedVisit, Track, VisitTime } from 'core';
import type { FC } from 'react';
import type { Item } from './chart.view.js';
import { ChartView } from './chart.view.js';

export interface Row {
  colors: [string, string?];
  track: Track.Type;
  visits: (VisitTime | MissedVisit)[];
  legs: LegWithStats[];
}

interface Props {
  items: Row[];
}

export const Chart: FC<Props> = ({ items }) => {
  const data: Item[] = [];
  items.forEach((item) =>
    item.legs.forEach((leg) => {
      const source = leg.from?.control._id;
      const target = leg.to?.control._id;
      if (!source || !target) return;
      data.push({ source, target, value: 1 });
    }),
  );
  return ChartView({ data });
};
