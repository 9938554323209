import type { UsePromiseResult } from '../../utils/promise.js';
import type { CourseTrack } from './types.js';

type Action<T> =
  | { type: 'INITIALIZED'; tracks: CourseTrack<T>[] }
  | { type: 'TRACK_UPDATED'; data: UsePromiseResult<T>; trackId: string };

export const reducer = <T>(
  state: Record<string, CourseTrack<T>>,
  action: Action<T>,
): Record<string, CourseTrack<T>> => {
  switch (action.type) {
    case 'INITIALIZED':
      return action.tracks.reduce<Record<string, CourseTrack<T>>>(
        (acc, track) => ({ ...acc, [track.track._id]: track }),
        {},
      );
    case 'TRACK_UPDATED':
      return {
        ...state,
        [action.trackId]: {
          ...state[action.trackId],
          data: action.data,
        },
      };
    default:
      return state;
  }
};
