import type { FC } from 'react';
import ChordChard from '../../../components/charts/chord-chart.js';

interface Props {
  data: Item[];
}

export interface Item {
  source: string;
  target: string;
  value: number;
}

export const ChartView: FC<Props> = ({ data }) => (
  <ChordChard data={data} size={500} />
);
