import type { LegTimedStats } from 'core';
import type { LegDef } from './types.js';

export const legDefToString = (leg: LegDef): string =>
  `${leg.from.type === 'start' || leg.index === 0 ? 'S' : leg.index} - ${
    leg.to.type === 'finish' ? 'F' : leg.index + 1
  }`;

const min = (
  a: number | undefined,
  b: number | undefined,
): number | undefined => {
  if (a === undefined && b === undefined) return undefined;
  if (a !== undefined && b !== undefined) return a < b ? a : b;
  return a ?? b;
};

const max = (
  a: number | undefined,
  b: number | undefined,
): number | undefined => {
  if (a === undefined && b === undefined) return undefined;
  if (a !== undefined && b !== undefined) return a > b ? a : b;
  return a ?? b;
};

export const bestWorst = (
  acc: { best: LegTimedStats; worst: LegTimedStats },
  current: LegTimedStats,
): { best: LegTimedStats; worst: LegTimedStats } => {
  const { best, worst } = acc;

  return {
    best: {
      meters: min(best.meters, current.meters),
      seconds: min(best.seconds, current.seconds),
      secondsPerMeter: min(best.secondsPerMeter, current.secondsPerMeter),
      altitude: {
        increase: min(best.altitude.increase, current.altitude.increase),
        decrease: min(best.altitude.decrease, current.altitude.decrease),
      },
    },
    worst: {
      meters: max(worst.meters, current.meters),
      seconds: max(worst.seconds, current.seconds),
      secondsPerMeter: max(worst.secondsPerMeter, current.secondsPerMeter),
      altitude: {
        increase: max(worst.altitude.increase, current.altitude.increase),
        decrease: max(worst.altitude.decrease, current.altitude.decrease),
      },
    },
  };
};

export const asScale = (
  best: number | undefined,
  worst: number | undefined,
  value: number | undefined,
): number => {
  if (value === undefined) return 0.5;

  const range = (worst ?? value) - (best ?? value);
  if (range === 1) return 0.5;

  const dist = value - (best ?? value);
  return dist / range;
};
